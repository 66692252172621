export const testimonials = [
  {
    person: "Michael Trowe - Chief Product Office Cloud",
    testimonial: `I am pleased to recommend Alex Pop for any future freelance opportunities. Alex worked with us at Haufe Group from 20/09/2023 to 30/06/2024, during which time he consistently demonstrated exceptional skill, professionalism, and dedication to his work.
        Alex helped our clients streamline their business processes by creating a cloud-native multi-edit Microfrontend application integrated in a third-party document management system. He also delivered a modern React-based web solution for API documentation, making our platform more competitive as we expanded our API strategy.
        In addition, with his help we have created an advanced Identity and Access Management platform that improves security and user-experience.
        Alex is highly self-motivated and always met deadlines, even when working under tight schedules. Alex consistently worked very independently and reliably, which significantly reduced the need for oversight and allowed our team to focus on other critical tasks. His excellent communication skills and transparent approach ensured that we were always on the same page. He provided regular updates and was always available for discussions, making collaboration seamless and efficient.
        It was a pleasure working with Alex, and I have no doubt that he will be a tremendous asset to any team or project he chooses to join. I wholeheartedly recommend Alex for any freelance opportunities and am confident that he will continue to excel in his future endeavors.
        Please feel free to contact me if you require any further information or would like to discuss Alex's qualifications in more detail.
        `,
  },
  {
    person: "Marius Schumann - Head of Technology",
    testimonial: `Alex was the software architect responsible for the frontend architecture of a master data management solution that was developed from scratch from mid-2018 till the end of 2020.
        During this time, he dedicated himself to this task with great commitment. He managed to conceptualise and implement a modular and clean-code based, complex single-page app that more than exceeded user expectations in terms of design and usability.
        I got to know Alex as a very constructive person who not only wanted to understand the technologies but also the business model behind them. He independently led a frontend development team. He gave me (as head of development) honest and meaningful feedback, which helped me to make the right decisions.
        I would work with Alex again at any time.`,
  },
  {
    person: "Kalpana Sharma - Engineering Manager",
    testimonial: `It is with great pleasure that I recommend Alexandru as a highly skilled Frontend Engineer with exceptional React expertise and a true team player. 

    Alexandru possesses in-depth understanding of React principles, coupled with their ability to implement best practices. During our 2 years tenure we worked together I witnessed him tackle complex challenges with ease, consistently delivering top-notch code that is well-structured, maintainable, and highly performant. Beyond technical skills, Alexandru is an extraordinary team player. Alexandru excels in collaborating effectively with cross-functional teams, fostering a positive and inclusive work environment. He is always quick to offer guidance, share knowledge, and provide support, which significantly enhances the overall productivity and harmony of the team.
    
    Alexandru consistently demonstrates a strong work ethic and takes ownership of their responsibilities. Furthermore, He embraces a growth mindset, constantly seeking opportunities to expand the knowledge and stay updated of the latest developments in frontend development.
    
    While I will definitely miss working with him but I wholeheartedly recommend Alexandru for any organisation that requires excellent Frontend skills and an outstanding team player. His technical proficiency, collaborative nature, and dedication to craft make him an invaluable asset. Alexandru will undoubtedly continue to excel and contribute significantly in any role he undertakes.`,
  },
  {
    person: "Kris Kater - Solutions Architect",
    testimonial: `I really enjoyed working together with Alex. He is a very skilled developer with solid front-end and back-end experience. Besides his technical skills, he is also very communicative and has very good analytical skills, spotting gaps in designs early on. An absolute master in React/Redux.`,
  },
  {
    person: "Bhagyashri Kulkarni - Senior Frontend Engineer",
    testimonial: `I am thrilled to recommend Alexandru for his exceptional skills as a senior software engineer. Within the React ecosystem, he exhibits unparalleled technical prowess. Alexandru is not only a pro at debugging and monitoring but also takes ownership of projects, delivering multiple features with finesse.

    His proactive approach to optimizing CI/CD time by discovering issues with our Docker/Travis setup and leveraging GitHub Actions is commendable. Furthermore, Alexandru has actively contributed to enhancing core web vital scores, ensuring top-notch user experiences.
    
    As a T-shaped developer, he embodies the perfect balance between depth and breadth of knowledge, making him an invaluable asset to any team. Collaborative, helpful, and a true team player, Alexandru is always willing to support others and foster a positive work environment.
    
    I highly recommend Alexandru as a capable, motivated software engineer for any organization.`,
  },
  {
    person: "Katherine Japutra - Senior Scrum Master",
    testimonial: `I've had the opportunity to work with Alex in the same team and he is an absolute pleasure to work with.
    Overall, Alex is a great team member and without his tremendous support, we wouldn't be able to go through a smooth transition of team members changes.
    Not only he showed solid technical knowledge, he also communicates well, always supportive and realiable, and most importantly, he brought clarity and focus to the team by not hesitating to ask the right questions.
    On top of that, he's proactive, always open to new ideas and doesn't shy away from experimenting on new things.
    I had really positive experience of working with Alex and I would no doubt be happy to work with him again.`,
  },
  {
    person: "Cezar Dascal - Senior Software Engineer",
    testimonial: `I remember vividly working with Alex. In my opinion he's a top notch programmer. While being very committed and pragmatic, he is also a sophisticated professional, he puts a lot of emphasis on technical skills and technical accuracy. On final note, I have to mention that one thing I remember from the days I and Alexandru worked together: he was simply the best communicator in the room, period. It goes without saying that he would be a great match for you, too!`,
  },
  {
    person: "Sandor Ban - Frontend Software Engineer",
    testimonial: `He is a very knowledgeable, well organized person, capable of delivering high quality code even working with deadlines. He's advice is helpful most of the time, and it was really great working with him.`,
  },
]
