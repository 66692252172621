import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"

import isSSR from "../../utils/isSSR"
import { testimonials } from "./testimonials.data"

const styles = {
  container: css`
    padding: 20px;
    margin: 20px auto;
    background-color: #1c1c1c;
    opacity: 0.85;
    border-radius: 12px;
  `,
  header: css`
    text-align: center;
    margin-bottom: 16px;

    span {
      margin-left: 8px;
    }
  `,
  sliderItem: css`
    cursor: pointer;
    background-color: #353535;
    padding: 12px;
    border-radius: 12px;

    &:hover {
      background-color: #4a4a4a;
    }
  `,
  sliderItemText: css`
    font-style: italic;
    font-size: 18px;
    line-height: 28px;
  `,
}

const Testimonials = () => {
  const [width, setWindowWidth] = useState(!isSSR ? window.innerWidth : 0)

  useEffect(() => {
    const reportWindowSize = () => {
      setWindowWidth(window.innerWidth)
    }
    if (!isSSR) {
      window.addEventListener("resize", reportWindowSize)
    }

    return () => {
      if (!isSSR) {
        window.removeEventListener("resize", reportWindowSize)
      }
    }
  }, [])

  return (
    !isSSR && (
      <>
        <div
          css={styles.container}
          style={{
            maxWidth: `${width - 50}px`,
          }}
        >
          <h2 css={styles.header}>What people say about me</h2>
          <h4 css={styles.header}>
            Swipe to see more
            <span>👉 </span>
          </h4>
          <Swiper
            effect="fade"
            spaceBetween={30}
            slidesPerView={width > 1200 ? 3 : width > 1000 ? 2 : 1}
            autoHeight
          >
            {testimonials.map(tm => (
              <SwiperSlide key={tm.person}>
                <div css={styles.sliderItem}>
                  <h4>{tm.person}</h4>
                  <p css={styles.sliderItemText}>"{tm.testimonial}"</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    )
  )
}

export default Testimonials
